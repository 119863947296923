body {
  font-family: 'Arial', sans-serif;
  background-color: #f3b91a; /* Fondo amarillo claro */
  color: #ffffff; /* Texto en blanco */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 400px;
  text-align: center;
}

h1 {
  font-size: 36px; /* Tamaño más grande */
  margin-bottom: 10px; /* Menos espacio debajo */
  color: rgb(255, 255, 255); /* Texto en blanco */
  margin-top: 0; /* Elimina el margen superior */
}

form {
  display: flex;
  flex-direction: column;
}

input[type="text"] {
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-family: 'Arial', sans-serif;
}

input[type="text"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
}

button[type="submit"] {
  padding: 15px 30px;
  background-color: #ff9900;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Arial', sans-serif;
}

button[type="submit"]:hover {
  background-color: #2e2e2e;
}

p {
  margin-top: 20px;
  font-family: 'Arial', sans-serif;
  color: rgb(255, 255, 255); /* Texto en blanco */
}

.error {
  color: red;
  font-family: 'Arial', sans-serif;
}
